import { Trans, useTranslation } from 'next-i18next';
import Image from 'next/image';
import Link from 'next/link';
import type { FC } from 'react';
import Container from '@/components/layouts/Container';
import { systemConfig } from './systemConfig';

export const NotFoundPage: FC = () => {
  const { t } = useTranslation(systemConfig.i18nNamespaces);

  return (
    <Container title={t('system:notFound.title')}>
      <main className="mt-header-mobile max-w-8xl desktop:mt-header-desktop desktop:px-8 min-h-content mx-auto flex w-full grow flex-col px-4">
        <div className="desktop:py-32 m-auto flex max-w-[478px] shrink-0 flex-col items-center justify-center space-y-2 py-16 text-center">
          <Image
            src="/static/icons/NotFound.svg"
            alt={t('system:notFound.title')}
            width={240}
            height={240}
            className="!mb-8"
          />
          <h1
            data-testid="not-found-title"
            className="text-neutral-90 text-xl font-bold"
          >
            {t('system:notFound.title')}
          </h1>
          <p className="text-neutral-70 text-sm">
            <Trans
              i18nKey={'system:notFound.description'}
              t={t}
              // eslint-disable-next-line tailwindcss/no-custom-classname
              components={[
                <Link
                  key="home-link"
                  className="text-primary-60 hover:text-primary-40 font-semibold hover:underline"
                  href="/"
                />,
              ]}
            />
          </p>
        </div>
      </main>
    </Container>
  );
};
